<template>
  <div v-if="settings">
    <v-checkbox
      :disabled="!editable"
      v-model="settings.parcelReference"
      label="Include Parcel Reference"
      @change="updateSetting()"
    ></v-checkbox>
    <v-checkbox
      :disabled="!editable"
      style="margin-top: -1rem; margin-right: 20px"
      @change="updateSetting()"
      v-model="settings.team"
    >
      <template v-slot:label>
        <span> Teams </span>
        <v-tooltip max-width="400" bottom color="primary">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="primary">mdi-information</v-icon>
          </template>
          <span
            >Teams is designed for and can be enabled for Peeps accounts with many properties that
            are managed in teams. In this way, team members can focus on selected properties
            assigned to the team.
          </span>
        </v-tooltip>
      </template>
    </v-checkbox>

    <v-checkbox
      :disabled="!editable"
      style="margin-top: -1rem; margin-right: 20px"
      @change="updateSetting()"
      v-model="settings.formatPhone"
    >
      <template v-slot:label>
        <span> Format Phone Number </span>
      </template>
    </v-checkbox>

    <v-text-field
      @input="updateSettingDebounce()"
      v-model="settings.listViewRows"
      label="List view rows"
      :disabled="!editable"
    ></v-text-field>

    <v-combobox
      :disabled="!editable"
      v-model="settings.bccEmails"
      @input="updateSettingDebounce()"
      :search-input.sync="searchBccEmail"
      hide-selected
      label="Bcc Emails"
      multiple
      persistent-hint
      small-chips
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching "<strong>{{ searchBccEmail }}</strong
              >". Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import Organization from '../../services/organization';
const org = new Organization();
import accountMixin from '../../mixins/account';
export default {
  mixins: [accountMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      settings: null,
      timeout: null,
      searchBccEmail: ''
    };
  },
  created() {
    this.getSetting();
  },
  methods: {
    updateSettingDebounce() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.updateSetting();
      }, 1000);
    },
    updateSetting() {
      org.updateSetting(this.id, this.settings);
      const { id } = this.$store.state.loggedInAs;
      if (this.id === id) {
        this.getOrgSetting();
      }
    },
    getSetting() {
      org.getSetting(this.id).then(settings => {
        this.settings = settings;
      });
    }
  }
};
</script>

<style>
</style>
